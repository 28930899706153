<template>
  <div class="row">
    <!-- start chat users-->
    <div class="col-xl-4 col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="media mb-3">
            <b-avatar size="md" variant="dark" class="text-light mr-1" style="cursor: pointer"
            ><span class="">{{ admin.name | initials }}</span></b-avatar
            >
            <div class="media-body">
              <h5 class="mt-0 mb-0 font-15">
                <router-link to="/contacts/profile" class="text-reset"
                >{{admin.name}}</router-link
                >
              </h5>
              <p class="mt-1 mb-0 text-muted font-14">
                <small class="mdi mdi-circle text-success"></small> Online
              </p>
            </div>
            <div>
              <a href="javascript: void(0);" class="text-reset font-20">
                <i class="mdi mdi-cog-outline"></i>
              </a>
            </div>
          </div>

          <!-- start search box -->
          <form class="search-bar mb-3">
            <div class="position-relative">
              <input
                  type="text"
                  class="form-control form-control-light"
                  placeholder="Search drivers .."
                  @keyup="onSearch"
              />
              <span class="mdi mdi-magnify"></span>
            </div>
          </form>
          <!-- end search box -->
          <template v-if="searchMode && isSearching">
            <skeleton :count="5"></skeleton>
          </template>
          <template v-if="searchMode && !isSearching ">
            <h6 class="font-13 text-muted text-uppercase mb-2">Drivers</h6>
            <div class="row">
              <div class="col">
                <simplebar data-simplebar style="max-height: 498px">
                  <a
                      href="javascript:void(0);"
                      class="text-body"
                      v-for="(driver, index) in drivers"
                      :key="driver['id']"
                      @click="setupDriverChat(driver)"
                  >
<!--                    @click="chatUsername(item.name, item.image)" -->
                    <div class="media p-2">
                      <div class="position-relative">
                        <b-avatar size="md" variant="dark" class="text-light mr-2" style="cursor: pointer"
                        ><span class="">{{ driver.name | initials }}</span></b-avatar
                        >
                      </div>
                      <div class="media-body">
                        <h5 class="mt-0 mb-0 font-14">
                          <span
                              class="float-right text-muted font-weight-normal font-12"
                          >
<!--                            You can put something here like notification if needed -->

                          </span
                          >
                          {{driver.name}} {{driver['other_names']}}
                        </h5>
                        <p class="mt-1 mb-0 text-muted font-14">
                          <span class="w-75">status: {{driver.status}}</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </simplebar>
                <!-- end slimscroll-->
              </div>
              <!-- End col -->
            </div>
          </template>

          <h6 class="font-13 text-muted text-uppercase mb-2">Recent Chats</h6>
          <!-- users -->
          <div class="row">
            <div class="col">
              <simplebar data-simplebar style="max-height: 498px">
                <a
                    href="javascript:void(0);"
                    class="text-body"
                    v-for="(item, index) in chatUsers"
                    :key="index"
                    @click="chatDriver(item)"
                >
                  <div class="media p-2 border-bottom">
                    <div class="position-relative">
<!--                        <span-->
<!--                            class="user-status"-->
<!--                            :class="{-->
<!--                            online: item.status === 'online',-->
<!--                            busy: item.status === 'offline',-->
<!--                            'do-not-disturb': item.status === 'do-not-disturb',-->
<!--                          }"-->
<!--                        ></span>-->
                      <b-avatar size="md" variant="light" class="text-dark mr-2" style="cursor: pointer"
                      ><span class="">{{ item.name | initials }}</span></b-avatar
                      >
                    </div>
                    <div class="media-body">
                      <h5 class="mt-0 mb-0 font-14">
                        <div
                            class="float-right text-muted font-weight-normal font-12"
                        >
                          <template v-if="item['pendingSupportMessagesForAdmin'] > 0">
                            <br>
                            <span class="badge badge-pill badge-success float-right mt-2"> {{item['pendingSupportMessagesForAdmin']}}</span>
                          </template>
                        </div
                        >
                        {{ item.name }}
                      </h5>
                      <p class="mt-1 mb-0 text-muted font-14">
<!--                        <span class="w-75">{{ item.lastMessage === '' ? 'Click to start chatting' : item.lastMessage }}</span>-->
                        {{formatTimestamp(item.lastMessageTime)}}
                      </p>
                    </div>
                  </div>
                </a>
              </simplebar>
              <!-- end slimscroll-->
            </div>
            <!-- End col -->
          </div>
          <!-- end users -->
        </div>
        <!-- end card-body-->
      </div>
      <!-- end card-->
    </div>
    <!-- end chat users-->

    <!-- chat area -->
    <div class="col-xl-8 col-lg-8">
      <div class="card">
        <div class="card-body py-2 px-3 border-bottom border-light" v-if="chatUser">
          <div class="media py-1" >
            <b-avatar size="md" variant="light" class="text-dark mr-1" style="cursor: pointer"
            ><span class="">{{ chatUser.name | initials }}</span></b-avatar
            >
            <div class="media-body">
              <h5 class="mt-2 mb-0 font-15">
                <router-link to="/contacts/profile" class="text-reset">
                  {{ chatUser.name }}
                </router-link>
              </h5>
<!--              <p class="mt-1 mb-0 text-muted font-12">-->
<!--                <small class="mdi mdi-circle" :class="{'text-success': chatUser.status === 'online', 'text-danger': chatUser.status === 'offline'}"></small>-->
<!--                {{chatUser.status}}-->
<!--              </p>-->
            </div>

          </div>
        </div>
        <div class="card-body">
            <simplebar data-simplebar style="max-height: 460px" id="scrollID">
              <template v-if="chatUser">
                <ul class="conversation-list chat-app-conversation">
                <li
                    class="clearfix"
                    v-for="(data, index) in chatMessagesData"
                    :key="index"
                    :class="{ odd: data.sentBy === 'admin' }"
                >
                  <template v-if="data.type === 'support'">
                  <div class="chat-avatar">
                    <b-avatar size="md" variant="light" class="text-dark mr-1" style="cursor: pointer"
                    >
                      <template v-if="data.sentBy === 'admin'">
                        <span class="">{{ admin.name | initials }}</span>
                      </template>
                      <template v-else>
                        <span class="">{{ chatUser.name | initials }}</span>
                      </template>


                    </b-avatar
                    >
                  </div>
                  <div class="conversation-text">
                    <div class="ctext-wrap">
                      <p><i>{{ data.message }}</i></p>
                      <small>{{ formatTimestamp(data.sentAt) }}</small>
                    </div>
                    <div
                        class="card mt-2 mb-1 shadow-none border text-left"
                        v-if="data.file === true"
                    >
<!--                      <div class="p-2">-->
<!--                        <div class="row align-items-center">-->
<!--                          <div class="col-auto">-->
<!--                            <div class="avatar-sm">-->
<!--                              <span class="avatar-title bg-primary rounded"-->
<!--                              >PDF</span-->
<!--                              >-->
<!--                            </div>-->
<!--                          </div>-->
<!--                          <div class="col pl-0">-->
<!--                            <a-->
<!--                                href="javascript:void(0);"-->
<!--                                class="text-muted font-weight-medium"-->
<!--                            >minton-presentation.pdf</a-->
<!--                            >-->
<!--                            <p class="mb-0">2.3 MB</p>-->
<!--                          </div>-->
<!--                          <div class="col-auto">-->
<!--                            &lt;!&ndash; Button &ndash;&gt;-->
<!--                            <a-->
<!--                                href="javascript:void(0);"-->
<!--                                class="btn btn-link btn-lg text-muted"-->
<!--                            >-->
<!--                              <i class="dripicons-download"></i>-->
<!--                            </a>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                  </div>
                  </template>

<!--                  <b-dropdown-->
<!--                      class="conversation-actions"-->
<!--                      toggle-class="btn-sm btn-link text-reset p-0"-->
<!--                      variant="black"-->
<!--                      right-->
<!--                  >-->
<!--                    <template v-slot:button-content>-->
<!--                      <i class="mdi mdi-dots-vertical font-18"></i>-->
<!--                    </template>-->
<!--                    <a class="dropdown-item" href="#">Copy Message</a>-->
<!--                    <a class="dropdown-item" href="#">Edit</a>-->
<!--                    <a class="dropdown-item" href="#">Delete</a>-->
<!--                  </b-dropdown>-->

                </li>
              </ul>
              </template>
            </simplebar>
            <div class="row">
              <div class="col">
                <div class="mt-2 bg-light p-3 rounded">
                  <form
                      class="needs-validation"
                      @submit.prevent="formSubmit"
                      name="chat-form"
                      id="chat-form"
                  >
                    <div class="row">
                      <div class="col mb-2 mb-sm-0">
                        <input
                            type="text"
                            v-model="form.message"
                            class="form-control border-0"
                            placeholder="Enter your text"
                        />

                      </div>
                      <div class="col-sm-auto">
                        <div class="btn-group">
<!--                          <a href="#" class="btn btn-light" v-if="chatUser">-->
<!--                            <i class="fe-paperclip"></i>-->
<!--                          </a>-->
                          <button
                              type="submit"
                              class="btn btn-success chat-send btn-block"
                              :disabled="!chatUser"
                          >
                            <i class="fe-send"></i>
                          </button>
                        </div>
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row-->
                  </form>
                </div>
              </div>
              <!-- end col-->
            </div>

          <!-- end row -->
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end chat area-->
  </div>
</template>

<script>
import appConfig from "../../../../../app.config.json";
import { required } from "vuelidate/lib/validators";
import {getDatabase, get, ref, push, set, serverTimestamp, onValue, update} from "firebase/database";
import { Skeleton } from 'vue-loading-skeleton';
import moment from "moment";
import {chatItem, chatUserItem} from "../../../../utils/functions";

/**
 * Starter component
 */
export default {
  components:{
    Skeleton
  },
  data() {
    return {
      chatUsers: [],
      chatMessagesData: [],
      form: {
        message: "",
      },
      username: "Designer",
      chatUser: null,
      searchMode: false,
      mDrivers: []
    };
  },
  computed: {
    admin(){
      return this.$store.getters.GET_USER_INFO
    },
    drivers() {
      return this.$store.getters.GET_DRIVERS;
    },
    isSearching() {
      return this.$store.getters.SECTION_LOADER;
    }
  },
  watch: {
    chatUser: function (value) {
        if(!value)return;
        this.getSelectedUserMessages()
    }
  },
  methods: {

    chatDriver(driver){
        this.chatUser = driver;
       console.log(driver)
        this.updateAdminPendingMessages()
       this.markUserChatAsRead(driver)
    },

    markUserChatAsRead(driver){
      const payload = {
        type: 'support',
        id: driver['id']
      }
      this.$store.dispatch('setNotificationMessagesAsRead', payload)
    },

    setupDriverChat(driver) {

      const db = getDatabase();

      const userRef = ref(db, 'users/' + driver.id);

      const data =  chatUserItem(driver, 'support');


      // check if user ref has already not been created
      // if not, create a chat channel for user
      get(userRef).then((snapshot) => {

        console.log('setupDriverChat called', this.chatUser)

        if (!snapshot.exists()) {
          set(userRef, data).then(() => {
            this.chatUser = data;
          }).then(() => {
            this.searchMode = false
          })
        }else{
          this.updateAdminPendingMessages()
        }

      }).catch((error) => {
        console.error(error);
      });

    },

    updateAdminPendingMessages(newValue = 0){
      console.log('updateAdminPendingMessages called', this.chatUser)
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);
      const updateData = {
        'pendingSupportMessagesForAdmin': newValue,
      }
      update(userRef, updateData);
    },

    formatTimestamp(value){
      return moment(value).calendar();
    },

    onSearch(event){
      console.log(event.target.value)
      let value = event.target.value;
      if(value === ''){
        this.searchMode = false;
        this.$store.dispatch('clearDrivers')
        return;
      }
      this.searchMode = true
      this.$store.dispatch('searchDriver', value)
    },

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    formSubmit() {

      if(this.form.message === ''){
        return;
      }
      if(!this.chatUser)
        return;

      const db = getDatabase();

      const chatRef = ref(db, 'chats/' + this.chatUser.id);
      const newChatRef = push(chatRef);

      const item = chatItem(this.form.message, this.chatUser.id, this.chatUser.name, 'support',)

      set(newChatRef, item).then(() => {
        // Data saved successfully!
        this.form.message = ''
        this.scrollToBottom()
        this.updateDriverUser(item['message'], item['sentAt'])

      }).catch((error) => {
        // The write failed...
        console.log(error)
      });
    },

    updateDriverUser(lastMessage, lastMessageTime){
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);

      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {

          const map = snapshot.val();
          let pendingSupportMessagesForDriver = map['pendingSupportMessagesForDriver'];
          pendingSupportMessagesForDriver = pendingSupportMessagesForDriver + 1;

          const updateData = {
            'lastMessage': lastMessage,
            'lastMessageTime': lastMessageTime,
            'pendingSupportMessagesForDriver': pendingSupportMessagesForDriver,
          }

          update(userRef, updateData);

        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

      this.updateAdminPendingMessages();

    },

    getPreviousChatUsers(){
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if(!data){
          return;
        }
        const arr = [];
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            let user = data[key];
            arr.push(user)
          }

        }

        arr.sort(function(b, a){return a['lastMessageTime']-b['lastMessageTime']})
        console.log('data', arr)
        this.chatUsers = arr
      });
    },

    getSelectedUserMessages(){

      this.chatMessagesData = [];
      const db = getDatabase();
      const chatRef = ref(db, 'chats/' + this.chatUser.id);
      onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        if(!data){
          return;
        }

        // console.log('data', data);
        //  = data.sort(function(a, b){return a-b});
//// Mark as read
        const arr = [];
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            let chat = data[key];

            if(chat['type'] === 'support'){
              arr.push(chat)
              if(chat['sentTo'] === 'admin' && chat['read'] === false ){

                const updateData = {
                  'recipientId': this.admin.id,
                  'recipientName': this.admin.name,
                  'read': true
                }
                const upRef = ref(db, 'chats/' + this.chatUser.id + '/' + key)
                //update(upRef, updateData);
              }
            }

          }


        }

        arr.sort(function(a, b){return a['sentAt']-b['sentAt']})
        this.chatMessagesData = arr;
        // console.log('chatMessagesData', this.chatMessagesData)
        this.scrollToBottom();

      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = document.querySelector('#scrollID .simplebar-content-wrapper');
        if(!container){
          return;
        }
        // container.scrollTo({ top: 100000000000000000000000000000000, behavior: "smooth"});
        container.scrollTo({ top: 100000000000000000000000000000000});
      })
      //behavior: "smooth"
    },

  },

  created() {
    this.getPreviousChatUsers()
    this.scrollToBottom();
    this.$store.dispatch('setPageTitle','Chat');
  }

};
</script>

